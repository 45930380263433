let scrollTop = 0;

/**
 * 元素到屏幕底部的距离
 * @param {element} element
 * @param {number} position 距离底部的距离
 */
function isEleSrollIntoViewPostion(element, position = 0) {
  if (!element) {
    return;
  }
  const offset = element.getBoundingClientRect();
  const offsetTop = offset.top;
  const offsetBottom = offset.bottom;
  // 进入可视区域
  if (offsetTop - window.innerHeight + position <= 0 && offsetBottom >= 0) {
    return true;
  } else {
    // console.log('移出可视区域');
  }
}

function ifEnterViewPostion(element, distance = 0) {
  if (!element) return;
  const offset = element.getBoundingClientRect();
  const offsetTop = offset.top;
  setTimeout(function() {
    scrollTop = document.getElementsByTagName("body")[0].scrollTop;
  }, 0);
  // console.log(document.getElementsByTagName('body')[0].scrollTop);

  if (
    offsetTop < distance &&
    document.getElementsByTagName("body")[0].scrollTop - scrollTop > 0
  ) {
    return "true";
  } else if (
    offsetTop < distance + 200 &&
    document.getElementsByTagName("body")[0].scrollTop - scrollTop < 0
  ) {
    return "false";
  }
}

// 旋转角度
function getAngle(cen, first, second) {
  // cen  : 中心点 [0,0]
  // first : 开始点 [1,3]
  // second : 结束位置 [3,4]
  var f_c_x = first[0] - cen[0],
    f_c_y = cen[1] - first[1],
    s_c_x = second[0] - cen[0],
    s_c_y = cen[1] - second[1];
  var c =
    Math.sqrt(f_c_x * f_c_x + f_c_y * f_c_y) *
    Math.sqrt(s_c_x * s_c_x + s_c_y * s_c_y);
  if (c == 0) return -1;
  var angle = Math.acos((f_c_x * s_c_x + f_c_y * s_c_y) / c);
  // 第一象限
  if (cen[0] - second[0] < 0 && cen[1] - second[1] < 0) {
    return angle;
    // 第二象限
  } else if (cen[0] - second[0] < 0 && cen[1] - second[1] > 0) {
    return angle;
    // 第三象限
  } else if (cen[0] - second[0] > 0 && cen[1] - second[1] < 0) {
    return 2 * Math.PI - angle;
    // 第四象限
  } else if (cen[0] - second[0] > 0 && cen[1] - second[1] > 0) {
    return 2 * Math.PI - angle;
  }
}
function isElementInViewport(targetElement) {
  if (!targetElement) {
    return false; // 元素未找到，返回 false
  }

  // 获取元素的位置信息
  const rect = targetElement.getBoundingClientRect();

  // 判断元素是否在视口中
  const isInViewport =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  return isInViewport;
}
function animateOnScroll(selector) {
  const elements = document.querySelectorAll(selector);

  // 检查是否获取到元素
  if (elements.length === 0) {
    console.warn(`No elements found for selector: ${selector}`);
    return;
  }

  // 创建 Intersection Observer 实例
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 元素进入可视窗口时添加类
        entry.target.classList.add("visible");
        // 可选：停止观察
        observer.unobserve(entry.target);
      }
    });
  });

  // 为每个元素注册观察
  elements.forEach((element) => {
    observer.observe(element);
  });
}

export default {
  isEleSrollIntoViewPostion,
  ifEnterViewPostion,
  getAngle,
  isElementInViewport,
  animateOnScroll,
};
