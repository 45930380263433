// import request from '@/utils/request'

export function commonRequest(url, data) {
  return new Promise((resolve, reject) => {
    fetch(`${window.location.protocol}//it.yingzhongshare.com/yingzhong-security/external/api/jPress/req`, {
      mode: 'cors',
      body: JSON.stringify({
        data: {
          url,
          paramMap: data
        }
      }),
      method: 'POST'
    }).then(res => {
      res.json().then(parsedRes => {
        resolve(parsedRes);
      }).catch(err => {
        reject(err);
      })
    }).catch(err => {
      reject(err);
    })
  })
}

// 根据文章ID获取文章详情
export function getArticleDetail(id) {
  return commonRequest('/api/article/detail', { id })
}



// 根据分类获取文章列表
// data: {categoryId: number, pageSize: number, pageNumber: number}
export function getArticleListByDetail(data) {
  return commonRequest('/api/article/paginate', data)
}

// 根据分类获取文章列表
// data: {categoryId: number, index: number}
export async function getRelativeArticle(data) {
  const { index, categoryId } = data;
  const indexNum = Number(index)
  let computedPageSize = 3;

  let remainder;
  while (Math.random() >= 0) {
    remainder = indexNum % computedPageSize;
    if (indexNum == 0 || (remainder !== 0 && remainder !== computedPageSize - 1)) {
      break;
    }
    computedPageSize++;
  }

  const computedPageNumber = Math.ceil(indexNum / computedPageSize);

  const res = await getArticleListByDetail({
    categoryId,
    pageSize: computedPageSize,
    pageNumber: computedPageNumber
  })

  const list = res && res.data && res.data.page && res.data.page.list || [];
  const prevIndex = remainder - 1;
  const nextIndex = remainder + 1;
  const prevPage = list[prevIndex];
  const nextPage = list[nextIndex];
  return { prevPage, nextPage };
}