<template>
  <!-- 小迈简介 -->
  <div class="article-list relative" v-loading="listLoading">
    <div
      class="article-item h-[446px] md:h-[471px] sm:!h-[376px]"
      v-for="(item, index) in listData"
      :key="index"
      @click="handleShowDetail(item.categoryId, item.id, index)"
    >
      <div class="article-img h-[225px] md:h-[256px] sm:!h-[188px]">
        <img
          :src="`https://help.yingzhongshare.com/${item.showImage}`"
          alt=""
        />
      </div>
      <div class="article-content sm:!text-[18px] sm:!leading-[30px]">
        <p class="article-title">{{ item.title }}</p>
        <p class="article-time">
          <span>{{
            item.created.replace(/(\d{4})-(\d{2})-(\d{2}).*/, "$1.$2.$3")
          }}</span>
          <span class="article-arrow"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "utils/Config";

export default {
  name: "AritcleList",
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleShowDetail(categoryId, articleId, index) {
      window.location.href = `${Config.domain}/dynamicDetail.html?categoryId=${categoryId}&id=${articleId}&index=${index}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  display: flex;
  flex-wrap: wrap;
  margin: -16px;
  min-height: 120px;
  .article-item {
    display: flex;
    flex-direction: column;

    flex: 0 1 calc(33.333% - 32px);
    margin: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    cursor: pointer;
    transition: all 0.3s;

    .article-img {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .article-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 24px;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: 0px;
      .article-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        font-variation-settings: "opsz" auto;
        color: #888888;
        .article-arrow {
          width: 23px;
          height: 14px;
          background: url(./assets/arrow.png) no-repeat center;
          background-size: cover;
        }
      }
    }

    &:hover {
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
    }
  }
  @media (max-width: 1200px) {
    .article-item {
      flex: 0 1 calc(50% - 16px);
      margin: 8px;
    }
  }
  @media (max-width: 768px) {
    .article-item {
      flex: 0 1 100%;
    }
  }
}
</style>
