import style from './loading.module.css'
import loadingUrl from './loading.svg'

function getLoadingImg(el) {
    //查找图片中有data-role为loading的图片
    return el.querySelector('img[data-role=loading]');
  }


function createLoadingImg() {
    const img = document.createElement('img')

    img.dataset.role = 'loading'

    img.src = loadingUrl

    img.className = style.loading
    
    return img
}

export default function (el, binding) {
    const curImg = getLoadingImg(el)

    if(binding.value) {
        if(!curImg) {
            const loadingImg = createLoadingImg()

            el.appendChild(loadingImg)
        }
    } else {
        if(curImg) {
            curImg.remove()
        }
    }
}