var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"header fixed inset-x-0 z-[999] text-[#fff] transition-all duration-1000 px-88",class:{
      down: _vm.theme === 'white' || _vm.isOpen ? false : _vm.scrollDirection === 'down',
      white: _vm.isOpen ? false : _vm.isWhite,
    },style:(_vm.theme === 'white'
        ? {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }
        : {})},[_c('div',{staticClass:"header-main mx-auto flex items-center justify-between h-[80px] sm:h-[56px]"},[_c('div',{staticClass:"header-left sm:!w-[104px]"},[_c('a',{attrs:{"href":"/"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWhite && !_vm.isOpen),expression:"isWhite && !isOpen"}],attrs:{"src":require("./assets/logo_xmiles_top-b.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWhite || _vm.isOpen),expression:"!isWhite || isOpen"}],attrs:{"src":require("./assets/logo_xmiles_top-w.png"),"alt":""}})])]),_c('nav',{staticClass:"header-menu flex items-center gap-[3.33vw] text-center text-[16px] sm:hidden"},_vm._l((_vm.gData.btns),function(item,index){return _c('div',{key:index,staticClass:"header-menu-item cursor-pointer relative",class:{ active: _vm.activeIndex == index },on:{"click":function($event){return _vm.jump(item.url)}}},[(item.url)?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e(),(!item.url)?_c('div',[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.children),expression:"item.children"}],staticClass:"sub-nav"},_vm._l((item.children),function(itemChildren,index){return _c('div',{key:index,staticClass:"sub-nav-item"},[_c('span',{on:{"click":function($event){return _vm.jumpRecruit(itemChildren.url)}}},[_vm._v(_vm._s(itemChildren.name))])])}),0)]):_vm._e()])}),0),_c('div',{staticClass:"header-menu-btn hidden sm:block",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWhite && !_vm.isOpen),expression:"isWhite && !isOpen"}],attrs:{"src":require("./assets/icon_menu_b.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWhite || _vm.isOpen),expression:"!isWhite || isOpen"}],attrs:{"src":require("./assets/icon_menu.png"),"alt":""}})])])]),_c('nav',{staticClass:"px-88 header-menu-mobile fixed top-0 left-0 z-[100] hidden sm:block",class:{
      open: _vm.isOpen,
    }},_vm._l((_vm.gData.mobileBtns),function(item,index){return _c('div',{key:index,staticClass:"header-menu-item cursor-pointer relative",class:{ active: _vm.gData.index == index },on:{"click":function($event){return _vm.jump(item.url)}}},[(item.url)?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }