<template>
  <div class="nav-wrap">
    <nav class="son-nav w-1440 px-88 flex items-center h-[72px] sm:h-[48px]">
      <div
        v-for="item in sonNavData"
        :key="item.id"
        @click="scrollTo(item.id)"
        :class="[
          'nav-item sm:!text-[14px] sm:!mr-[24px] sm:last:!mr-0',
          { active: activeId === item.id },
        ]"
      >
        {{ item.name }}
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    sonNavData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeId: null,
    };
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);
    this.sonNavData.forEach((item) => {
      const elements = document.querySelectorAll(`#${item.id}`);
      elements.forEach((element) => {
        if (element) {
          observer.observe(element);
        }
      });
    });
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.activeId = entry.target.id; // 设置活跃的ID
        }
      });
    },
    scrollTo(id) {
      const elements = document.querySelectorAll(`#${id}`);
      let targetElement = null;
      // 找到第一个可见的元素
      elements.forEach((element) => {
        if (element.offsetParent !== null) {
          targetElement = element; // 如果存在显示的元素，保存它
        }
      });
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-wrap {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px 0px #e1e1e1;
  z-index: 10;
}
.son-nav {
  overflow: auto;
  .nav-item {
    cursor: pointer;
    margin-right: 48px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #888888;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }

  .nav-item.active {
    color: #222222;
  }
}
</style>
