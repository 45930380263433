<template>
  <footer class="xm-footer">
    <div class="w-1440 px-88 md:hidden">
      <div class="xm-footer-top">
        <img
          class="xm-footer-top-logo"
          src="../xmHeader/assets/logo_xmiles_top-b.png"
          alt=""
        />
        <div class="flex flex-1 justify-between">
          <div class="xm-footer-top-list xm-footer-top-about">
            <span class="xm-footer-top-list-title">联系我们</span>
            <span class="xm-footer-top-list-span"
              >商务洽谈：<span style="color: #222222;text-decoration: underline"
                >bd@xmiles.cn</span
              ></span
            >
            <span class="xm-footer-top-list-span"
              >合作推广：<span style="color: #222222;text-decoration: underline"
                >market@xmiles.cn</span
              ></span
            >
            <span class="xm-footer-top-list-span" style="margin-bottom: 32px;"
              >加入我们：<span style="color: #222222;text-decoration: underline"
                >hr@xmiles.cn</span
              ></span
            >
            <div class="xm-footer-top-list-company">
              <p>广州总部：广州市天河区华夏路32号太平洋金融大厦30、31全层</p>
              <p>
                成都分公司：四川省成都市高新区吉瑞三路99号皇庭国际中心A座1905
              </p>
              <p>北京分公司：北京市朝阳区奥运村街道北京文化创意大厦C3</p>
            </div>
          </div>
          <div class="xm-footer-top-list">
            <span class="xm-footer-top-list-title">关于我们</span>
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('小迈简介')"
              >小迈简介</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('管理团队')"
              >创始团队</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('企业历程')"
              >企业历程</span
            >

            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('合作伙伴')"
              >合作伙伴</span
            >
          </div>
          <div class="xm-footer-top-list">
            <span class="xm-footer-top-list-title">小迈业务</span>
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('业务模式')"
              >业务模式</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('业务成果')"
              >业务成果</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('开发者成功案例')"
              >开发者成功案例</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('核心优势')"
              >核心优势</span
            >
          </div>
          <div class="xm-footer-top-list">
            <span class="xm-footer-top-list-title">组织文化</span>
            <!-- <span class="xm-footer-top-list-span"  @click="handleSpanLocation('招聘岗位')">招聘岗位</span> -->
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('小迈价值观')"
              >小迈价值观</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('迈进计划')"
              >迈进计划</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('迈动力沙龙')"
              >迈动力沙龙</span
            >
            <span
              class="xm-footer-top-list-span"
              @click="handleSpanLocation('迈fans俱乐部')"
              >迈fans俱乐部</span
            >
          </div>
        </div>
      </div>
      <div class="xm-footer-bottom">
        <div class="xm-footer-bottom-font">
          <span class="xm-footer-span"
            ><a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备15016492号</a
            >
            | ICP经营许可证：粤B2-20200925 | 广州小迈网络科技有限公司 版权所有 |
            Copyright © 2021 XMiles. All Rights Reserved.</span
          >
        </div>
      </div>
    </div>
    <div class="px-88 hidden md:block footerMobile">
      <div class="footerMobileTop">
        <img
          src="../xmHeader/assets/logo_xmiles_top-b.png"
          class="img_logo_svg mb-[48px] sm:!w-[124px] sm:!h-[24px] sm:!mb-[44px]"
        />
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('/index.html')"
        >
          <span class="normalFont sm:!text-[15px]">首页</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('/about.html')"
        >
          <span class="normalFont sm:!text-[15px]">关于我们</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('/business.html')"
        >
          <span class="normalFont sm:!text-[15px]">小迈业务</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('/culture.html')"
        >
          <span class="normalFont sm:!text-[15px]">组织文化</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('/dynamic.html')"
        >
          <span class="normalFont sm:!text-[15px]">小迈动态</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>
        <div
          class="w-fit flex justify-between items-center mb-[48px] sm:!mb-[34px]"
          @click="jump('social')"
        >
          <span class="normalFont sm:!text-[15px]">加入我们</span>
          <img :src="this.arrowRight" class="normalImg" />
        </div>

        <div
          class="w-fit flex justify-between items-center mb-[24px] sm:!mb-[18px]"
        >
          <span class="normalFont sm:!text-[15px]">联系我们</span>
        </div>

        <div class="footerTopInformation sm:!text-[14px]">
          <p class="mb-[16px] sm:mb-[12px]">
            商务洽谈: <span class="talk">bd@xmiles.cn</span>
          </p>
          <p class="mb-[16px] sm:mb-[12px]">
            合作推广: <span class="talk">market@xmiles.cn </span>
          </p>
          <p class="mb-[16px] sm:mb-[24px]">
            加入我们: <span class="talk">hr@xmiles.cn</span>
          </p>
        </div>
        <div
          class="xm-footer-top-list-company sm:!text-[12px] sm:!leading-[24px]"
        >
          <p>广州总部：广州市天河区华夏路32号太平洋金融大厦30、31全层</p>
          <p>
            成都分公司：四川省成都市高新区吉瑞三路99号皇庭国际中心A座1905
          </p>
          <p>北京分公司：北京市朝阳区奥运村街道北京文化创意大厦C3</p>
        </div>
      </div>
      <div class="footerMobileBottom">
        <div class="footerMobileBottomFont sm:!text-[12px]">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备15016492号</a
          >
          | ICP经营许可证：粤B2-20200925 广州小迈网络科技有限公司 版权所有
          Copyright © 2024 XMiles. All Rights Reserved.
        </div>
      </div>
    </div>
    <TopBtn></TopBtn>
  </footer>
</template>

<script>
import Config from "utils/Config";
import TopBtn from "../topBtn/index";

export default {
  name: "xmFooter",
  components: { TopBtn },
  props: {
    hasPc: Boolean,
    index: Number,
  },
  data() {
    return {
      arrowRight:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABCUlEQVRoQ+2XMQ7CMBAE9/IQJKJUPISWjofyAhoKesrkE/mAUSQqisixvBuwNvXd2TMbxXGgkSca4YBBfi1JJ+JESAb8apHEFo91IsXqSI1OhCS2eKwTKVZHanQiJLHFY6smklKKYRgeAOaIuI7jOBfvbGMjA+SVUjoBeHZdd1bBVAVZJPZ9fwBwB3BUwlQH2QuGArIHDA1EDUMFUcLQQVQwEpBvmIi4TdN02XhUrJYbZItNxdlCT0QBsUilgqggqCBKCBqIGoICsgdEdZDPfeT/f+ObuVhtOVtq11I/v7U3uzbPIErbOWs5kRxLyhonorSds5YTybGkrHEiSts5azmRHEvKmmYSeQOaIXwzBcfFEgAAAABJRU5ErkJggg==",
    };
  },
  methods: {
    handleSpanLocation: function(name) {
      let urlArray = {
        小迈简介: "/about.html#aboutIntro",
        企业历程: "/about.html#aboutCourse",
        管理团队: "/about.html#aboutManage",
        合作伙伴: "/about.html#aboutPartner",
        业务模式: "/business.html#businessModel",
        核心优势: "/business.html#businessAdvantages",
        业务成果: "/business.html#businessAchievement",
        开发者成功案例: "/business.html#businessCase",
        小迈价值观: "/culture.html#culture",
        迈进计划: "/culture.html#culturePlan",
        迈动力沙龙: "/culture.html#cultureSalon",
        迈fans俱乐部: "/culture.html#cultureClub",
      };
      // window.location.href = window.location.origin + urlArray[name];
      if (name === "社会招聘") {
        window.open("https://app.mokahr.com/social-recruitment/xmiles/39484");
      } else if (name === "校园招聘") {
        window.open("https://app.mokahr.com/campus-recruitment/xmiles/37612");
      } else {
        window.location.href = Config.domain + urlArray[name];
      }
    },
    jump(url) {
      if (url === "social") {
        window.open("https://app.mokahr.com/social-recruitment/xmiles/39484");
      } else if (url === "school") {
        window.open("https://app.mokahr.com/campus-recruitment/xmiles/37612");
      } else {
        window.location.href = `${Config.domain}${url}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-footer {
  width: 100%;
  overflow: hidden;
  // height: 330px;
  background: #fafafa;
  border-radius: 2px;
  position: relative;
  z-index: 10;
  .xm-footer-top-list-company {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0em;
    color: #888888;
  }
  .xm-footer-top {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    margin-top: 60px;
    margin-bottom: 83px;
    position: relative;
    z-index: 1;
  }

  .xm-footer-top-logo {
    width: 184px;
    height: 36px;
    margin-right: win(136);
  }

  .xm-footer-top-list {
    display: flex;
    flex-direction: column;
  }

  .xm-footer-top-list-title {
    margin-bottom: 24px;
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #222222;
  }

  .xm-footer-top-list-span {
    margin-bottom: 12px;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0em;
    color: #666666;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .xm-footer-top-list-span:hover {
    cursor: pointer;
    color: #3292ff;
  }

  .xm-footer-bottom {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0em;
    color: #aaaaaa;
  }

  .xm-footer-span {
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0em;
    color: #aaaaaa;
    a {
      color: #222222;
      &:hover {
        color: #3292ff;
      }
    }
  }
  .xm-footer-bottom:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.footerMobile {
  position: relative;
  padding-top: 60px;
  width: 100%;
  z-index: 2;
  .xm-footer-top-list-company {
    margin-bottom: 32px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0em;
    color: #888888;
  }
  .footerMobileTop {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .img_logo_svg {
    width: 184px;
    height: 36px;
  }

  .normalFont {
    font-family: PingFang SC;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    color: #222222;
  }
  .normalImg {
    height: 24px;
    width: 24px;
  }
  .footerTopInformation {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #666666;
    .talk {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
      text-decoration: underline;
    }
  }

  .footerMobileBottom {
    width: 100%;
    padding: 24px 0;
    border-top: 1px solid #f0f0f0;
  }
  .footerMobileBottomFont {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    a {
      color: #888888;
    }
  }
}
</style>
