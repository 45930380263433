<template>
  <transition name="fade">
    <div v-show="showTopBtn" class="top-btn" @click="goTopEvt"></div>
  </transition>
</template>

<script>
export default {
  name: "topBtn",

  computed: {},
  data() {
    return {
      showTopBtn: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle); // 清除滚动监听
  },
  methods: {
    scrollHandle() {
      const { top, height } = document
        .getElementsByTagName("body")[0]
        .getBoundingClientRect();

      if (Math.abs(top) >= height / 2) {
        this.showTopBtn = 1;
      } else {
        this.showTopBtn = 0;
      }
    },
    goTopEvt() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style lang="less" scoped>
.top-btn {
  width: 41px;
  height: 41px;
  background: #ffffff;
  // box-shadow: 0px 8px 45px 0px rgba(239,244,250,1);
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  // position: absolute;
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  bottom: 5%;
  right: 3%;

  &::after {
    content: "";
    width: 15px;
    height: 2px;
    background: #222222;
    border-radius: 1px;
    display: block;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 15px;
  }

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #222;
    border-right: 2px solid #222222;
    transform: rotate(-45deg);
    box-sizing: border-box;

    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 23px;
  }

  &:hover {
    border: none;
    background-color: #3292ff;
    box-shadow: 0px 10px 60px 0px rgba(239, 244, 250, 1);
    border-radius: 4px;

    &::after {
      background-color: #fff;
    }
    &::before {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }
  }
}
@media screen and (max-width: 1200px) {
  .top-btn {
    width: 41px;
    height: 41px;
    // top: 60%;
    // right: 5%;

    &::after {
      width: 15px;
      height: 2px;

      left: 50%;
      margin-left: -7px;
      position: absolute;
      top: 10px;
    }

    &::before {
      width: 10px;
      height: 10px;

      left: 50%;
      margin-left: -5px;
      position: absolute;
      top: 22px;
    }

    // &:hover{
    //     background-color: #3292FF;
    //     box-shadow: 0px 10px 60px 0px rgba(239,244,250,1);
    //     border-radius: 4px;

    //     &::after{
    //         background-color: #fff;
    //     }
    //     &::before{
    //         border-top: 2px solid #fff;
    //         border-right: 2px solid #fff;
    //     }
    // }
  }
}
</style>
