export default {
  PCData: {
    top: [
      {
        img: require("../assets/img/pc/index/top/img_banner_01@1x.png"),
      },
    ],
    advantages: [
      {
        title: "聚量广告营销平台",
        icon1: require("../assets/img/pc/index/worth/1/icon_value_1.png"),
        icon2: require("../assets/img/pc/index/worth/1/icon_value_2.png"),
        img: require("../assets/img/pc/index/worth/1/img_value.png"),
        descList: [
          {
            subtitle: "一站式全域营销",
            desc: "聚合全球20+主流营销平台，高效率实现一站式的全域营销能力",
            icon: require("../assets/img/pc/index/worth/1/icon_value_01_dec.png"),
          },
          {
            subtitle: "自动化投放管理",
            desc:
              "基于大数据和算法实现从创意素材，计划创建，预算，营销效果的全链路自动化管理",
            icon: require("../assets/img/pc/index/worth/1/icon_value_02_dec.png"),
          },
          {
            subtitle: "实时全链路BI",
            desc:
              "提供从营销到后端质量全链路的实时数据，帮助优化师和广告主及时调整投放策略和动作",
            icon: require("../assets/img/pc/index/worth/1/icon_value_03_dec.png"),
          },
          {
            subtitle: "创意中心",
            desc:
              "创意秒级分析，快速挖掘爆量素材；素材生成引擎，提供高效率的素材合并、素材二次制作等能力提升素材制作效率",
            icon: require("../assets/img/pc/index/worth/1/icon_value_04_dec.png"),
          },
        ],
      },
      {
        title: "野马商业化平台",
        icon1: require("../assets/img/pc/index/worth/2/icon_value_1.png"),
        icon2: require("../assets/img/pc/index/worth/2/icon_value_2.png"),
        img: require("../assets/img/pc/index/worth/2/img_value.png"),
        descList: [
          {
            subtitle: "一站式广告聚合",
            desc: "一站式接入主流30+广告变现平台 大幅提升开发效率",
            icon: require("../assets/img/pc/index/worth/2/icon_value_01_dec.png"),
          },
          {
            subtitle: "商业化增长BI",
            desc:
              "基于APP赛道自主设计研发的增长分析模型和框架，赋能开发者快速具备行业领先的商业化增长能力",
            icon: require("../assets/img/pc/index/worth/2/icon_value_02_dec.png"),
          },
          {
            subtitle: "高效的广告聚合管理",
            desc:
              "傻瓜式配置工具，保障效果的情况下提升效率流量分组，A/B测试等诊断增长工具，帮助开发者探索最优策略",
            icon: require("../assets/img/pc/index/worth/2/icon_value_03_dec.png"),
          },
          {
            subtitle: "潮汐AI引擎",
            desc:
              "基于每个用户商业化数据和特征学习，智能实时为每个用户配置其最佳的商业策略",
            icon: require("../assets/img/pc/index/worth/2/icon_value_04_dec.png"),
          },
        ],
      },
      {
        title: "北极星开发者平台",
        icon1: require("../assets/img/pc/index/worth/3/icon_value_1.png"),
        icon2: require("../assets/img/pc/index/worth/3/icon_value_2.png"),
        img: require("../assets/img/pc/index/worth/3/img_value.png"),
        descList: [
          {
            subtitle: "交钥匙式开发者套件",
            desc:
              "将开发者所需的归因、支付、广告、零钱发放、账号等需求封装为开发者套件，赋能开发者通过简单的API接入即具备这些能力...",
            icon: require("../assets/img/pc/index/worth/3/icon_value_01_dec.png"),
          },
          {
            subtitle: "一站式合规赋能",
            desc:
              "为开发者提供一站式的合规SDK赋能，使开发者能够快速简单达到国家合规标准",
            icon: require("../assets/img/pc/index/worth/3/icon_value_02_dec.png"),
          },
          {
            subtitle: "多模态策略风控管理",
            desc: "设备维度特征风控策略 广告防作弊风控策略",
            icon: require("../assets/img/pc/index/worth/3/icon_value_03_dec.png"),
          },
          {
            subtitle: "数据BI引擎",
            desc: "为开发者赋予数字化能力，提供整套数字化运营能力",
            icon: require("../assets/img/pc/index/worth/3/icon_value_04_dec.png"),
          },
        ],
      },
      {
        title: "太极AIGC引擎",
        icon1: require("../assets/img/pc/index/worth/4/icon_value_1.png"),
        icon2: require("../assets/img/pc/index/worth/4/icon_value_2.png"),
        img: require("../assets/img/pc/index/worth/4/img_value.png"),
        descList: [
          {
            subtitle: "多元场景开箱即用",
            desc: "prompt管理、智能问答、人像优化 支持文生文、图生图 ...",
            icon: require("../assets/img/pc/index/worth/4/icon_value_01_dec.png"),
          },
          {
            subtitle: "计算资源智能调度",
            desc: "基于容器调度底层GPU资源，更高的利用率降低成本",
            icon: require("../assets/img/pc/index/worth/4/icon_value_02_dec.png"),
          },
          {
            subtitle: "灵活的API接入",
            desc: "Web、iOS、Android一站式解决动态参数适应多种场景模板使用",
            icon: require("../assets/img/pc/index/worth/4/icon_value_03_dec.png"),
          },
          {
            subtitle: "数据闭环",
            desc:
              "从内容生成到用户使用埋点全流程数据闭环，明晰内容生成质量和产品迭代方向",
            icon: require("../assets/img/pc/index/worth/4/icon_value_04_dec.png"),
          },
          {
            subtitle: "接入多个大模型",
            desc: "一键切换最优方案",
            icon: require("../assets/img/pc/index/worth/4/icon_value_05_dec.png"),
          },
          {
            subtitle: "在线调试",
            desc: "在线调试、模型调优，快速试错",
            icon: require("../assets/img/pc/index/worth/4/icon_value_06_dec.png"),
          },
        ],
      },
      {
        title: "HOLOPIX创作平台",
        icon1: require("../assets/img/pc/index/worth/5/icon_value_1.png"),
        icon2: require("../assets/img/pc/index/worth/5/icon_value_2.png"),
        img: require("../assets/img/pc/index/worth/5/img_value.png"),
        descList: [
          {
            subtitle: "高质量内容创作",
            desc: "利用AIGC技术，轻松生成各类高质量内容，降低技能专业依赖",
            icon: require("../assets/img/pc/index/worth/5/icon_value_01_dec.png"),
          },
          {
            subtitle: "美术降本增效",
            desc:
              "一键图像创作，快速完成前期风格设定和美术资源产出，有效缩短项目研发周期",
            icon: require("../assets/img/pc/index/worth/5/icon_value_02_dec.png"),
          },
          {
            subtitle: "私有化定制",
            desc: "针对业务特定需求和场景进行开发，提供专属内容生成服务",
            icon: require("../assets/img/pc/index/worth/5/icon_value_03_dec.png"),
          },
          {
            subtitle: "数据安全与稳定",
            desc: "私有模型训练，模型数据权限管理，保护项目的隐私和版权",
            icon: require("../assets/img/pc/index/worth/5/icon_value_04_dec.png"),
          },
        ],
      },
    ],
    extenets: [
      {
        isShowYiTxt: false,
        txtXContent: "累计开发者",
      },
      {
        isShowYiTxt: true,
        txtXContent: "月活跃用户",
      },
      {
        isShowYiTxt: true,
        txtXContent: "开发者营收",
      },
    ],
    successCase: [
      {
        title: "固真工作室",
        desc: "2021年1月孵化成立，初始团队7人，专注于益智答题休闲游戏赛道",
        imgList: require.context(
          "../assets/img/pc/index/successCase/guzhen",
          false,
          /\.(png|jpe?g|svg)$/
        ),
        markerList: [
          " ·2021年5月成功孵化出成语产品",
          "·2021年6月收入接近破亿",
          "·2022年7月产品累计服务用户破亿",
          "·截止至2023年8月，成为行业头部开发者",
        ],
        bgColor: "#0068FF",
      },
      {
        title: "无限工作室",
        desc: "2020年孵化成立，初始团队15人，专注于工具APP赛道",
        imgList: require.context(
          "../assets/img/pc/index/successCase/wx",
          false,
          /\.(png|jpe?g|svg)$/
        ),
        markerList: [
          " ·2020年1月多系列上线，多产品跑通",
          " ·2020年10月步数系列计步宝 日活/收入 破百万，累计用户过5亿 ·2021年5月收入破亿·2022年2月产品累计服务用户破十亿 ·截止至2023年8月，成为行业绝对头部开发者...",
        ],
        bgColor: "#00BFFF",
      },
      {
        title: "耀琦工作室",
        desc: "2021年7月孵化成立，初始团队7人，专注于天气产品赛道",
        imgList: require.context(
          "../assets/img/pc/index/successCase/yq",
          false,
          /\.(png|jpe?g|svg)$/
        ),
        markerList: [
          " ·2021年9月产品上线，11月跑通产品模型，开始发力推广",
          " ·2022年6月知雨天气单包累计活跃破亿",
          " ·2023年4月天气系列产品总日活破350万...",
        ],
        bgColor: "#5D38FF",
      },
    ],
    futureList: [
      {
        title: "丰富多样的节日礼包",
        img: require("../assets/img/pc/index/future/future1.png"),
        background: "rgba(0, 104, 255, 0.6)",
        backgroundHover: "rgba(0, 104, 255, 0.8)",
        hover: false,
      },
      {
        title: "有趣好玩的节庆活动",
        img: require("../assets/img/pc/index/future/future2.png"),
        background: "rgba(41, 66, 228, 0.6)",
        backgroundHover: "rgba(41, 66, 228, 0.8)",
        hover: false,
      },
      {
        title: "轻松愉悦的工作氛围",
        img: require("../assets/img/pc/index/future/future3.png"),
        background: "rgba(0, 104, 255, 0.6)",
        backgroundHover: "rgba(0, 104, 255, 0.8)",
        hover: false,
      },
      {
        title: "多方位关爱员工生活",
        img: require("../assets/img/pc/index/future/future4.png"),
        background: "rgba(75, 140, 15, 0.6)",
        backgroundHover: "rgba(75, 140, 15, 0.8)",
        hover: false,
      },
      {
        title: "重要纪念日送上真挚祝福",
        img: require("../assets/img/pc/index/future/future5.png"),
        background: "rgba(197, 116, 17, 0.6)",
        backgroundHover: "rgba(197, 116, 17, 0.8)",
        hover: false,
      },
    ],
  },
};
