<template>
  <header>
    <div
      class="header fixed inset-x-0 z-[999] text-[#fff] transition-all duration-1000 px-88"
      v-bind:class="{
        down: theme === 'white' || isOpen ? false : scrollDirection === 'down',
        white: isOpen ? false : isWhite,
      }"
      :style="
        theme === 'white'
          ? {
              backgroundColor: 'rgba(255, 255, 255, 1)',
            }
          : {}
      "
    >
      <div
        class="header-main mx-auto flex items-center justify-between h-[80px] sm:h-[56px]"
      >
        <div class="header-left sm:!w-[104px]">
          <a href="/">
            <img
              v-show="isWhite && !isOpen"
              src="./assets/logo_xmiles_top-b.png"
              alt=""
            />
            <img
              v-show="!isWhite || isOpen"
              src="./assets/logo_xmiles_top-w.png"
              alt=""
            />
          </a>
        </div>
        <nav
          class="header-menu flex items-center gap-[3.33vw] text-center text-[16px] sm:hidden"
        >
          <div
            class="header-menu-item cursor-pointer relative"
            v-for="(item, index) in gData.btns"
            :key="index"
            @click="jump(item.url)"
            v-bind:class="{ active: activeIndex == index }"
          >
            <div v-if="item.url">{{ item.name }}</div>
            <div v-if="!item.url">
              {{ item.name }}
              <div v-show="item.children" class="sub-nav">
                <div
                  v-for="(itemChildren, index) in item.children"
                  :key="index"
                  class="sub-nav-item"
                >
                  <span @click="jumpRecruit(itemChildren.url)">{{
                    itemChildren.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div class="header-menu-btn hidden sm:block" @click="isOpen = !isOpen">
          <img
            v-show="isWhite && !isOpen"
            src="./assets/icon_menu_b.png"
            alt=""
          />
          <img
            v-show="!isWhite || isOpen"
            src="./assets/icon_menu.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <nav
      class="px-88 header-menu-mobile fixed top-0 left-0 z-[100] hidden sm:block"
      v-bind:class="{
        open: isOpen,
      }"
    >
      <div
        class="header-menu-item cursor-pointer relative"
        v-for="(item, index) in gData.mobileBtns"
        :key="index"
        @click="jump(item.url)"
        v-bind:class="{ active: gData.index == index }"
      >
        <div v-if="item.url">{{ item.name }}</div>
      </div>
    </nav>
  </header>
</template>

<script>
import Config from "utils/Config";
export default {
  name: "xmHeader",
  props: {
    activeIndex: Number,
    theme: String,
  },
  data() {
    return {
      gData: {
        // logo :"//img.ibestfanli.com/xmilesWebsite2/img_logo_svg.svg",
        tabIcon: "//img.ibestfanli.com/xmOfficial/tabIcon.png",
        tabClose: "//img.ibestfanli.com/xmOfficial/tabClose.png",
        developerLogin:
          "http://it.yingzhongshare.com/index-frontend/index.html#/login",
        btns: [
          { name: "首页", url: `${Config.domain}/index.html` },
          { name: "关于我们", url: `${Config.domain}/about.html` },
          { name: "小迈业务", url: `${Config.domain}/business.html` },
          { name: "组织文化", url: `${Config.domain}/culture.html` },
          { name: "小迈动态", url: `${Config.domain}/dynamic.html` },
          {
            name: "加入我们",
            children: [
              {
                name: "社会招聘",
                url: "https://app.mokahr.com/social-recruitment/xmiles/39484",
              },
              {
                name: "校园招聘",
                url: "https://app.mokahr.com/campus-recruitment/xmiles/37612",
              },
            ],
          },
        ],
        mobileBtns: [
          { name: "首页", url: `${Config.domain}/index.html` },
          { name: "关于我们", url: `${Config.domain}/about.html` },
          { name: "小迈业务", url: `${Config.domain}/business.html` },
          { name: "组织文化", url: `${Config.domain}/culture.html` },
          { name: "小迈动态", url: `${Config.domain}/dynamic.html` },
          {
            name: "社会招聘",
            url: "https://app.mokahr.com/social-recruitment/xmiles/39484",
          },
          {
            name: "校园招聘",
            url: "https://app.mokahr.com/campus-recruitment/xmiles/37612",
          },
        ],
      },
      lastScrollTop: 0,
      scrollDirection: "",
      isRecruitShow: false,
      isOpen: false,
    };
  },
  computed: {
    isWhite() {
      return this.lastScrollTop > 80 || this.theme === "white";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop > this.lastScrollTop && scrollTop > 80) {
        this.scrollDirection = "down";
      } else if (scrollTop < this.lastScrollTop) {
        this.scrollDirection = "up";
      }

      this.lastScrollTop = scrollTop;
    },
    jump(url) {
      if (url) {
        window.location.href = url;
      }
    },
    jumpRecruit(url) {
      window.open(url);
    },
    //跳转开发者登录
    jumpDeveloperLogin() {
      window.location.href = this.gData.developerLogin;
    },
    toHome() {
      window.location.href = `${Config.domain}/index.html`;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-left {
  width: 147px;
}

.down {
  transform: translateY(-100%);
}
.header {
  // backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0);
}
.white {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.4);
  will-change: auto;
  color: #222;
  .active::after {
    background: #222 !important;
  }
  svg > path {
    fill: #222 !important;
  }
}

.header-menu {
  .active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 16px;
    height: 4px;
    background: #ffffff;
    border-radius: 2px;
    transform: translateX(-50%);
  }
  .header-menu-item:hover {
    .sub-nav {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  .sub-nav {
    position: absolute;
    width: 100px;
    height: 84px;
    left: 50%;
    padding: 10px;
    background-color: rgba(245, 245, 246, 0.8);
    text-align: center;
    border-radius: 0 0 2px 2px;
    transform: translate(-50%, 20px);
    opacity: 0;
    transition: opacity 0.3s linear, transform 0.6s ease;
    .sub-nav-item {
      color: #222;
      height: 32px;
      line-height: 32px;
      padding-bottom: 10px;
    }
  }
}

.header-menu-btn {
  width: 18px;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.header-menu-mobile {
  width: 100%;
  height: 100vh;
  padding-top: 56px;
  z-index: 99;
  background-color: #0068ff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
  color: #ffffff;
  .active {
    background: red;
  }

  .header-menu-item {
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.open {
  opacity: 1;
  pointer-events: auto;
}
</style>
